import React, { useState, useEffect } from 'react';
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from '../api/firebaseConfig';
import axios from 'axios';
import { updateUserProfile } from '../api/api';
import AvatarSelector from './AvatarSelector'; // Import AvatarSelector component
import './Menu.css';

const API_URL = 'https://us-central1-meme-ranking.cloudfunctions.net/api';

const Menu = ({ isOpen, onToggle, onNavigate, user, onLogout }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [isAvatarSelectorOpen, setIsAvatarSelectorOpen] = useState(false); // State for full-screen avatar selector

    useEffect(() => {
        const fetchUserAvatar = async () => {
            if (user && !avatarUrl) {
                try {
                    const idToken = await user.getIdToken();
                    const response = await axios.get(`${API_URL}/user`, {
                        headers: { Authorization: `Bearer ${idToken}` },
                    });
                    setAvatarUrl(response.data.avatarUrl || 'default-avatar.png');
                } catch (error) {
                    console.error('Error fetching user avatar:', error);
                }
            }
        };

        fetchUserAvatar();
    }, [user]);

    const handleAvatarChange = (newAvatarUrl) => {
        try {
            if (newAvatarUrl !== avatarUrl) {
                setAvatarUrl(newAvatarUrl);
                updateUserProfile({ avatarFilePath: newAvatarUrl });
            }
        } catch (error) {
            console.error('Error updating avatar URL:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1023);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const firebaseUser = result.user;

            if (firebaseUser.email && firebaseUser.email.endsWith('@bosromania.ro')) {
                const idToken = await firebaseUser.getIdToken(true);
                await axios.post(
                    `${API_URL}/auth/login`,
                    { email: firebaseUser.email },
                    { headers: { Authorization: `Bearer ${idToken}` } }
                );
                onNavigate('/');
            } else {
                await auth.signOut();
                alert("Access denied: You must use a @bosromania.ro email address.");
            }
        } catch (error) {
            console.error('Error during Google Sign-In:', error.message);
        }
    };

    const handleAvatarClick = () => {
        setIsAvatarSelectorOpen(true); // Open the avatar selector
    };

    const handleSelectorClose = () => {
        setIsAvatarSelectorOpen(false); // Close the avatar selector
    };

    const handleMouseLeave = () => {
        if (!isMobile) onToggle();
    };

    return (
        <div className="menu-container" style={{ zIndex: isOpen ? 1000 : 0 }}>
            <div className="icon-button toggle-icon" onClick={onToggle}>
                &#9776;
            </div>
            <div className={`side-menu ${isOpen ? 'open' : 'closed'}`} onMouseLeave={handleMouseLeave}>
                <div className="menu-item" onClick={() => { onNavigate('/'); onToggle(); }}>Home</div>
                <div className="menu-item" onClick={() => { onNavigate('/matchup'); onToggle(); }}>Game</div>
                <div className="menu-item" onClick={() => { onNavigate('/leaderboard'); onToggle(); }}>Leaderboard</div>

                {user ? (
                    <div className="user-section">
                        <div className="user-info">
                            <div className="avatar-container" onClick={handleAvatarClick}>
                                <img
                                    src={avatarUrl || 'default-avatar.png'}
                                    alt="User Avatar"
                                    className="current-avatar"
                                />
                            </div>
                            <p>Hello, {user.displayName}</p>
                        </div>

                        <button onClick={() => { onLogout(); onToggle(); }}>Logout</button>
                    </div>
                ) : (
                    <div className="auth-section">
                        <button onClick={handleGoogleSignIn}>Sign in with Google</button>
                    </div>
                )}
            </div>

            {/* Full-screen Avatar Selector */}
            {isAvatarSelectorOpen && (
                <AvatarSelector
                    currentAvatar={avatarUrl || 'default-avatar.png'}
                    onAvatarChange={handleAvatarChange}
                    onClose={handleSelectorClose}
                />
            )}
        </div>
    );
};

export default Menu;
