import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LeaderboardPage.css';

const LeaderboardPage = () => {
  const [memes, setMemes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [isTouchingMeme, setIsTouchingMeme] = useState(false);
  const [lastTap, setLastTap] = useState(0);
  const memesPerPage = 18;

  useEffect(() => {
    const fetchMemes = async () => {
      try {
        const response = await axios.get('https://us-central1-meme-ranking.cloudfunctions.net/api/memes');
        setMemes(response.data);
      } catch (error) {
        console.error('Error fetching memes:', error);
      }
    };

    fetchMemes();
  }, []);

  const indexOfLastMeme = currentPage * memesPerPage;
  const indexOfFirstMeme = indexOfLastMeme - memesPerPage;
  const currentMemes = memes.slice(indexOfFirstMeme, indexOfLastMeme);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getOrdinal = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100;
    return num + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  };

  const handleMemeClick = (meme) => {
    setSelectedMeme(meme);
    document.body.style.overflow = 'hidden';
  };

  const handleExitPreview = () => {
    if (selectedMeme) {
      const memeIndex = memes.indexOf(selectedMeme);
      const newPage = Math.floor(memeIndex / memesPerPage) + 1;
      setCurrentPage(newPage);
    }
    setSelectedMeme(null);
    document.body.style.overflow = ''; // Re-enable scrolling on the page
  };

  const handleOverlayClick = (e) => {
    // Double-tap only for mobile
    if (window.innerWidth < 768 || window.innerHeight < 500) {
      const currentTime = new Date().getTime();
      if (currentTime - lastTap < 300) {
        handleExitPreview();
      }
      setLastTap(currentTime);
    } else { // Desktop view
      if (e.target.classList.contains('meme-preview-overlay')) {
        e.stopPropagation();
        e.preventDefault();
        handleExitPreview();
      }
    }
  };

  const scrollMemePreview = (direction) => {
    if (!selectedMeme) return;

    const currentIndex = memes.indexOf(selectedMeme);
    const newIndex = currentIndex + direction;

    if (newIndex >= 0 && newIndex < memes.length) {
      setSelectedMeme(memes[newIndex]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedMeme) {
        if (event.key === 'ArrowUp') scrollMemePreview(-1);
        if (event.key === 'ArrowDown') scrollMemePreview(1);
        if (event.key === 'Escape') handleExitPreview(); // Exit preview on Escape key
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedMeme]);

  useEffect(() => {
    let touchStartX = 0;
    let touchEndX = 0;
    let touchStartY = 0;
    let touchEndY = 0;
    let lastTapTime = 0;
    const tapThreshold = 10; // Max movement for a tap
    const doubleTapDelay = 300; // Max delay between taps for double-tap
    const swipeThreshold = 30; // Minimum distance for a swipe
  
    const handleTouchStart = (e) => {
      if (e.touches.length > 1) {
        return; // Ignore if more than one touch
      }

      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].clientY;
      touchEndX = touchStartX;
      touchEndY = touchStartY;
      setIsTouchingMeme(true);
      e.preventDefault(); // Prevent subsequent click event
    };
  
    const handleTouchMove = (e) => {
      touchEndX = e.touches[0].clientX;
      touchEndY = e.touches[0].clientY;
    };
  
    const handleTouchEnd = (e) => {
      if (!selectedMeme) return;

      if (e.touches.length > 1) {
        return; // Ignore if more than one touch
      }
  
      const horizontalSwipeDistance = touchEndX - touchStartX;
      const verticalSwipeDistance = touchEndY - touchStartY;
  
      // Ignore touch events if touchEndY is too close to 0 (indicates incomplete touch)
      if (touchEndY < 50) {
        console.log("Touch end too low; ignoring swipe calculation.");
        setIsTouchingMeme(false);
        return;
      }
  
      // Determine if this was a tap
      const isTap =
      Math.abs(horizontalSwipeDistance) < tapThreshold &&
      Math.abs(verticalSwipeDistance) < tapThreshold;

    if (isTap) {
      const currentTime = new Date().getTime();
      const timeSinceLastTap = currentTime - lastTapTime;

      if(window.innerWidth > 768 && window.innerHeight > 500) {
        console.log("Detected tap on desktop; exiting preview.");
        handleExitPreview();
      }

      if (timeSinceLastTap < doubleTapDelay) {
        e.stopPropagation(); // Prevent the tap from propagating
        e.preventDefault(); // Prevent default behavior
        console.log("Detected double-tap; exiting preview.");
        handleExitPreview();
      }

      // Update lastTapTime for the next tap
      lastTapTime = currentTime;
    }

      // Horizontal swipe: close preview
      else if (
        Math.abs(horizontalSwipeDistance) > swipeThreshold &&
        Math.abs(horizontalSwipeDistance) > Math.abs(verticalSwipeDistance)
      ) {
        console.log("Horizontal swipe detected; exiting preview.");
        handleExitPreview();
      } 
      // Vertical swipe: scroll meme preview
      else if (Math.abs(verticalSwipeDistance) > swipeThreshold) {
        console.log("Vertical swipe detected; scrolling preview.");
        scrollMemePreview(verticalSwipeDistance > 0 ? -1 : 1);
      }
  
      setIsTouchingMeme(false);
    };
  
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
  
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [selectedMeme]);
  
  return (
    <div className="leaderboard-container">
      <h1>Leaderboard</h1>

      <div className="podium-container">
        {memes.slice(0, 3).map((meme, index) => (
          <div key={meme.id} className={`podium podium-${index + 1}`} onClick={() => handleMemeClick(meme)}>
            <div className="podium-step">
              <div className="elo-score-podium">{getOrdinal(index + 1)} - SCORE: {meme.eloScore}</div>
              <div className="image-container">
                {meme.fileType === 'video' ? (
                  <video 
                    src={meme.fileUrl} 
                    className="podium-image" 
                    controls={false} 
                    autoPlay 
                    loop 
                    muted
                    playsInline
                  />
                ) : (
                  <img src={meme.fileUrl} alt={meme.name} className="podium-image" />
                )}
              </div>
              <p>{meme.name}</p>
            </div>
          </div>
        ))}
      </div>

      <h2>Other Memes</h2>
      <div className="meme-grid">
        {currentMemes.slice(3).map((meme, index) => (
          <div key={meme.id} className="meme-item" onClick={() => handleMemeClick(meme)}>
            <div className="image-container">
              {meme.fileType === 'video' ? (
                <video 
                  src={meme.fileUrl} 
                  className="meme-image" 
                  controls={false} 
                  autoPlay 
                  loop 
                  muted
                  playsInline 
                />
              ) : (
                <img src={meme.fileUrl} alt={meme.name} className="meme-image" />
              )}
              <p className="elo-score">
                {getOrdinal(indexOfFirstMeme + index + 4)} - SCORE : {meme.eloScore}
              </p>
            </div>
            <p>{meme.name}</p>
          </div>
        ))}
      </div>

      <div className="pagination">
        {Array.from({ length: Math.ceil(memes.length / memesPerPage) }, (_, i) => (
          <button 
            key={i} 
            onClick={() => paginate(i + 1)} 
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
      </div>

      {selectedMeme && (
        <div className="meme-preview-overlay" onClick={handleOverlayClick}>
          <div className="meme-preview-content" onClick={(e) => e.stopPropagation()}>
            {selectedMeme.fileType === 'video' ? (
              <video src={selectedMeme.fileUrl} className="preview-meme" controls autoPlay loop />
            ) : (
              <img src={selectedMeme.fileUrl} alt={selectedMeme.name} className="preview-meme" />
            )}
            <div className="preview-elo-score">SCORE: {selectedMeme.eloScore}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaderboardPage;
