// frontend/src/App.js
import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Menu from './components/Menu'; 
import GamePage from './pages/GamePage';
import LeaderboardPage from './pages/LeaderboardPage';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import { useAuth } from './AuthContext';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const { user, handleLogout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  return (
    <div className="app-background">
      <Menu isOpen={isMenuOpen} onToggle={toggleMenu} onNavigate={navigate} user={user} onLogout={handleLogout} />
      <div className="main-content">
        <Routes>
          <Route
            path="/matchup"
            element={
              <ProtectedRoute>
                <GamePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leaderboard"
            element={
              <ProtectedRoute>
                <LeaderboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              user ? (
                <h1><span className="gradient-text">Meme</span> <span className="gradient-text">Ranking</span> <span className="gradient-text">Battle</span>, {user.displayName}!</h1>
              ) : (
                <h1><span className="gradient-text">Meme</span></h1>
              )
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
